<template>
  <div class="font-stolzl text-coolGray-900">
    <div class="h-screen flex flex-col">
      
      <!-- SPACE-->
      <div id="space" class="md:h-16"></div>
      <!-- SPACE-->
      
      <div class="flex flex-row flex-grow">
        
        <!-- LEFT-->
        <div class="w-1/2 bg-coolGray-100 h-full"></div>
        <!-- LEFT-->
        
        <!-- RIGHT-->
        <div class="w-1/2 h-full flex flex-row justify-center items-center">
          
          <!-- NEW USER PASS -->
          <div v-if="type == 'resetPass'" class="w-3/4">

              <form id="resetPass" @submit.prevent="resetPass">
                <div class="flex flex-col w-3/4">
                  <div class="text-coolGray-400 text-sm font-light">Email Adresse</div>
                  <div v-if="email" class="font-light text-base">{{email}}</div>
                </div>
            
                <!-- new pass -->
                <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="newPass && newPass !== ''" class="font-light text-coolGray-700 text-xs">Neues Passwort</div>
                  </transition>
                  <input id="newPass" name="newPass" v-model="newPass" type="password" placeholder="Neues Passwort" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>
                
                <input type="submit" value="Passwort ändern" class="transition duration-500 ease-in-out w-full mt-3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"/>
                
                <div v-if="newPassErrors && newPassErrors.length > 0" class="font-light text-base text-red-600">
                  {{newPassErrors}}
                </div>
              </form>
              
          </div>
          <!-- NEW USER PASS -->
          
          <div v-else-if="type == 'changeEmail'">
            CHANGING EMAIL
          </div>
          
          <div v-else-if="type == 'signup'" class="w-3/4">
            <div v-if="signupErrors && signupErrors.length > 0" class="font-light text-base text-red-600">
              {{signupErrors}}
            </div>
          </div>
          
          <div v-else-if="type == 'deleteAccount'" class="w-3/4">
            <div v-if="deleteAccountErrors && deleteAccountErrors.length > 0" class="font-light text-base text-red-600">
              {{deleteAccountErrors}}
            </div>
          </div>
          
        </div>
        <!-- RIGHT-->
        
      </div>
    </div>
    <!-- COMPONENTS-->
    <accountConfirmRegisterMutation />
    <confirmEmailChangeMutation />
    <setNewPassMutation />
    <confirmDeleteAccountMutation />
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/footer.vue';
import anime from 'animejs/lib/anime.es.js';

import showHide from '../mixins/showHide.vue';

import accountConfirmRegisterMutation from '../graphql/saleor/accountConfirmRegisterMutation.vue';
import confirmEmailChangeMutation from '../graphql/saleor/confirmEmailChangeMutation.vue';
import setNewPassMutation from '../graphql/saleor/setNewPassMutation.vue';
import confirmDeleteAccountMutation from '../graphql/saleor/confirmDeleteAccountMutation.vue';

export default {
  name: 'Confirm',
  props: ['slug'],
  components: { Footer, accountConfirmRegisterMutation, confirmEmailChangeMutation, setNewPassMutation, confirmDeleteAccountMutation },
  mixins: [ showHide ],
  
  data(){
      return{
        //signup
        signupErrors: null,
        
        //resetPass
        newPass:null,
        newPassErrors: null,
        
        //deleteAccount
        deleteAccountErrors: null,
        
        type: null,
        email: null,
        token: null,
        errors: {}
      };
  },
  
  computed: {
    confirmAccount(){
      return this.$store.state.data.confirmAccount;
    },
    confirmEmailChange(){
      return this.$store.state.data.confirmEmailChange;
    },
    setNewPass(){
      return this.$store.state.data.setNewPass;
    },
    deleteAccount(){
      return this.$store.state.data.confirmDeleteAccount;
    },
    header(){
      return this.$store.state.data.headers;
    }
  },
  
  watch: {
    header(){

      if(this.type == 'deleteAccount'){
        this.deleteAccount({
          token: this.token
        }).then(result => {
          if (result.error) {
            this.deleteAccountErrors = result.error.message;
          } else {
            var errors = result.data.accountDelete.accountErrors;
              if(errors.length == 0){
                this.deleteAccountErrors = "Dein Account wurde erfolgreich gelöscht.";
              } else {
                this.deleteAccountErrors = errors;
              }
          }
      });
    }
    }
  },
  
  mounted(){
    this.email = this.$route.query.email;
    this.token = this.$route.query.token;
    this.type = this.slug;
    
    if(this.type == 'signup'){
      this.signupUser();
    }
  },
  
  methods: {
    
    signupUser(){
      this.confirmAccount({
          email: this.email,
          token: this.token
        }).then(result => {
          if (result.error) {
            console.log(result.error.message);
            if(result.error.message.includes("expired")){
              //refreshToken, submit again
            }
          } else {
            console.log(result.data);
            var errors = result.data.confirmAccount.accountErrors;
              if(errors.length == 0){
                this.signupErrors = "Dein Account wurde erfolgreich erstellt. Du kannst dich nun einloggen.";
              } else {
                this.signupErrors = errors;
              }
          }
      });
    },
    
    resetPass(){
      this.setNewPass({
        token: this.token,
        email: this.email,
        pass: this.newPass
      }).then(result => {
        if (result.error) {
          this.newPassErrors = result.error.message;
        } else {
          var errors = result.data.setPassword.accountErrors;
            if(errors.length == 0){
              this.newPassErrors = "Dein Passwort wurde erfolgreich geändert.";
            } else {
              this.newPassErrors = errors;
            }
        }
      });
    },
    
  }
  
};
</script>