<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"accountConfirmRegisterMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const accountConfirmRegisterMutation = gql`
      mutation($email: String!, $token: String!) {
        confirmAccount(
          email: $email
          token: $token
        ) {
          accountErrors {
            field
            code
          }
          user {
            email
            isActive
          }
        }
      }
      `;
  
    const { execute: confirmAccount } = useMutation(accountConfirmRegisterMutation);
    
    store.commit('graphqlData', { item: 'confirmAccount', result: confirmAccount });
  },
}
</script>