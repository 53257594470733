<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"setNewPassMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const setNewPassMutation = gql`
      mutation($token: String!, $email: String!, $pass: String!) {
        setPassword(
          token: $token
          email: $email
          password: $pass
        ) {
          accountErrors {
            field
            code
          }
        }
      }
      `;
  
    const { execute: setNewPass } = useMutation(setNewPassMutation,{
      context: computed(() => store.state.data.headers)
    });
    
    store.commit('graphqlData', { item: 'setNewPass', result: setNewPass });
  },
}
</script>